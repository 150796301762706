import React from 'react';
import { ReactComponent as IconFacebook } from './assets/icons/facebook.svg';
import { ReactComponent as IconInstagram } from './assets/icons/instagram.svg';
import "./App.css";

class App extends React.Component {
  render = () => {
    return (
      <div className="card">
        <div className="header">
          <div className="logo">
            <a href="/">{'{ mduran }'}</a>
          </div>
          <div className="social">

          </div>
        </div>
        <div className="content">
          <div className="title-holder">
            <h1>Get ready for the change.</h1>
            <p>We're updating our website. Shoot us an email if you're curious.</p>
          </div>
          <a href="mailto:info@mduran.com.ar">
            <div className="cta">Send us an email</div>
          </a>
        </div>
        <div className="footer">
          
        </div>
      </div>
    );
  }
}

export default App;